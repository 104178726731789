import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { LinkContainer } from 'react-router-bootstrap'
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { BsPerson } from "react-icons/bs";
import { getUserDetails, updateUserProfile } from "../actions/userActions";
import { USER_UPDATE_PROFILE_RESET } from "../constants/userConstants";
import { logout } from '../actions/userActions'
import axios from "axios";

const ProfileScreen = ({ history }) => {
  const [showUpdateProfile, setShowUpdateProfile] = useState(false);
  const [hideButton, setHideButton] = useState(true);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [rewardPoints, setRewardPoints] = useState("");

  const [profileName, setProfileName] = useState("");
  const [profileAddress, setProfileAddress] = useState("");
  const [ProfileEmail, setProfileEmail] = useState("");
  /* const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('') 
    const [message, setMessage] = useState(null)*/

  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  /* const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
    const { success } = userUpdateProfile */

  const logoutHandler = () => {
    dispatch(logout())
  }

  useEffect(() => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    if (!userInfo) {
      history.push("/login");
    } else {
      if (!user) {
        dispatch(getUserDetails("profile"));
        dispatch({ type: USER_UPDATE_PROFILE_RESET });
      } else {
        setName(user.name);
        setEmail(user.email);
        setAddress(user.address);
      }
    }

    axios
      .get(`/api/users/profile`, config)
      .then((res) => {
        console.log(res);
        setProfileName(res.data.name);
        setProfileAddress(res.data.address);
        setProfileEmail(res.data.email);
        setRewardPoints(res.data.rewardPoints);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [history, userInfo, dispatch, user]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(updateUserProfile({ id: user._id, name, email, address }));
    setShowUpdateProfile(false);
    setHideButton(true);

    // window.location.reload(false)
    /* if (password !== confirmPassword) {
            setMessage('passwords do not match')
        } else { 
        }*/
  };

  const showProfileUpdate = () => {
    setShowUpdateProfile(true);
    setHideButton(false);
  };

  return (
    <Row>
      <Col md={4}>
        {/*  {message && <Message variant='light'>{message}</Message>} */}
        {error && <Message variant="light">{error}</Message>}
        {/*  {success && <Message variant='success'>Profile Updated</Message>} */}
        {loading && <Loader />}
        <Form className="centerProfile mt-2">
          {!showUpdateProfile ? (
            <>
              <Form.Group style={{ textAlign: "center" }} controlId="name">
                <BsPerson size={120} />
              </Form.Group>
              <Form.Group style={{ textAlign: "center" }} controlId="name">
                <div className="oneLine" style={{ color: "#000" }}>
                  <h3>{profileName}</h3>
                  <h4 style={{ margin: "0" }}>Your Reward Points</h4>
                  <spna style={{ fontSize: "2rem", fontWeight: "600" }}>
                    {Math.round((rewardPoints + Number.EPSILON) * 100) / 100}
                  </spna>

                  <h5>
                    <span
                      style={{
                        color: "blue" /* fontSize: "2rem", fontWeight: "600" */,
                      }}
                    >
                      {" "}
                      1 point = ₹1 Rupee{" "}
                    </span>{" "}
                    <br />
                    Increase your Reward Points By
                    <br /> Ordering More Products
                  </h5>
                  <p>Email : {ProfileEmail}</p>
                </div>
                <div style={{ color: "#000" }}>
                  <p>Address : {profileAddress}</p>
                </div>
              </Form.Group>
            </>
          ) : null}

          {showUpdateProfile ? (
            <>
              <Form.Group controlId="name">
                <Form.Label> Your Name </Form.Label>
                <Form.Control
                  type="name"
                  placeholder="Enter Your Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="email">
                <Form.Label>Your Email ID</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter Your Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="address">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="address"
                  placeholder="Enter Your Address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                ></Form.Control>
              </Form.Group>

              {/* <Form.Group controlId='password'>
                        <Form.Label>Password</Form.Label>
                        <Form.Control type='password' placeholder='Enter Your Password' value={password} onChange={(e) => setPassword(e.target.value)}></Form.Control>
                    </Form.Group>
                    <Form.Group controlId='confirmPassword'>
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control type='password' placeholder='Enter Your Confirm Password' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}></Form.Control>
                    </Form.Group> */}

              <div className="d-grid   justify-content-center">
                <Button
                  className="mt-2 justify-centent-center"
                  onClick={submitHandler}
                  variant="secondary"
                >
                  Update Your Profile
                </Button>
              </div>
            </>
          ) : null}
          {hideButton ? (
            <>
              <div className="d-grid   justify-content-center">
                <Button
                  className="mt-2 justify-centent-center"
                  onClick={showProfileUpdate}
                  variant="dark"
                >
                  Update Your Profile
                </Button>
              </div>

              <div className="d-grid   justify-content-center mt-2">
                <LinkContainer to='/login' id='LogOut'>
                  <Button variant="light" onClick={logoutHandler}>Logout</Button>
                </LinkContainer>
              </div>
            </>
          ) : null}
        </Form>
      </Col>
    </Row>
  );
};

export default ProfileScreen;
