import React from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { BsCardChecklist, BsBoxSeam, BsPerson } from "react-icons/bs";
import { Route } from "react-router-dom";
import { AiOutlineHome, AiOutlineShoppingCart } from "react-icons/ai";
import SearchBox from "../SearchBox";

const HeaderAndFooterMobile = ({ store, userInfo, cartItems }) => {
  return (
    <>
      <header className="headerShadow" style={{ position: "sticky", top: "0", zIndex: "5" }}>
        <Navbar bg="dark" className=" justify-content-evenly" stickey="top" variant="dark">
          <Container>
            <LinkContainer to="/">
              <Navbar.Brand>
                {" "}
                <i className="fas fa-store"></i> <span style={{ fontSize: "1.2rem" }}>{store.name}</span>
              </Navbar.Brand>
            </LinkContainer>

            <Route render={({ history }) => <SearchBox history={history} />} />
          </Container>
        </Navbar>
      </header>

      <footer>
        <Navbar
          className="footerNavbar justify-content-evenly"
          fixed="bottom"
          style={{ padding: "0.4rem 0 0.1rem 0", zIndex: "2" }}
        >
          <LinkContainer to="/" id="Home" style={{ justifyContent: "space-between" }}>
            <Nav.Link className="allicons">
              <div className="iconcenter">
                <AiOutlineHome size={25} />
              </div>
              <div className="iconcenter">Home</div>
            </Nav.Link>
          </LinkContainer>

          <LinkContainer to="/cart" id="cart">
            <Nav.Link className="allicons">
              <div className="cart-item">
                <h6>{cartItems.length}</h6>
              </div>
              <div className="iconcenter">
                <AiOutlineShoppingCart size={25} />
              </div>
              <div className="iconcenter">Cart</div>
            </Nav.Link>
          </LinkContainer>

          <LinkContainer to="/myorders" id="category">
            <Nav.Link className="allicons">
              <div className="iconcenter">
                <BsCardChecklist size={25} />
              </div>
              <div className="iconcenter">orders</div>
            </Nav.Link>
          </LinkContainer>

          <LinkContainer to="/bundle" id="bundle">
            <Nav.Link className="allicons">
              <div className="iconcenter">
                <BsBoxSeam size={25} />
              </div>
              <div className="iconcenter">Bundle</div>
            </Nav.Link>
          </LinkContainer>

          {userInfo ? (
            <LinkContainer to="/profile" id="Login">
              <Nav.Link className="allicons">
                <div className="iconcenter">
                  <BsPerson size={25} />
                </div>
                <div className="iconcenter">Profile</div>
              </Nav.Link>
            </LinkContainer>
          ) : (
            <LinkContainer to="/login" id="Login">
              <Nav.Link className="allicons">
                <div className="iconcenter">
                  <BsPerson size={25} />
                </div>
                <div className="iconcenter">Sign In</div>
              </Nav.Link>
            </LinkContainer>
          )}

          {userInfo && (userInfo.role.includes("admin") || userInfo.role.includes("superAdmin")) && (
            <NavDropdown title="Admin" id="adminmenu">
              <LinkContainer to="/admin/productlist" id="products">
                <NavDropdown.Item>Products</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/admin/orderlist" id="orders">
                <NavDropdown.Item>Orders</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/admin/cart" id="POS">
                <NavDropdown.Item>POS</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
          )}
        </Navbar>
      </footer>
    </>
  );
};

export default HeaderAndFooterMobile;
