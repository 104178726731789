import axios from "axios";
import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_SAVE_PAYMENT_METHOD,
  CART_SAVE_SHIPPING_ADDRESS,
  CART_SAVE_MARKETPLACE_ADDRESS,
} from "../constants/cartConstants";
import { MARKETPLACE_ID } from "../config";
const marketplaceId = MARKETPLACE_ID;

export const addToCartAdmin =
  (id, qty, price, name = null, item = null) =>
    async (dispatch, getState) => {
      if (item == null) {
        console.log(JSON.stringify(item));
        const { data } = await axios.get(`/api/marketplace/${marketplaceId}/products/${id}`);

        let itemPrice = price;
        let productName;
        if (name === null) {
          productName = data.name;
        } else {
          productName = name;
        }

        dispatch({
          type: CART_ADD_ITEM,
          payload: {
            product: data._id,
            name: productName,
            image: data.images[0],
            price: itemPrice,
            mrp: data.mrp,
            tax: data.tax,
            category: data.category,
            countInStock: data.countInStock,
            store: data.store,
            marketplace: data.marketplace,
            qty,
          },
        });

        localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
      }
      else {

        console.log("item details avilabale ,just updatng cart details")

        let itemPrice = price;
        let productName;
        if (name === null) {
          productName = item.name;
        } else {
          productName = name;
        }

        dispatch({
          type: CART_ADD_ITEM,
          payload: {
            product: item.product,
            name: productName,
            image: item.image,
            price: itemPrice,
            mrp: item.mrp,
            tax: item.tax,
            category: item.category,
            countInStock: item.countInStock,
            store: item.store,
            marketplace: item.marketplace,
            qty,
          },
        });

        localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
      }
    };

export const addToCart = (id, qty) => async (dispatch, getState) => {
  const { data } = await axios.get(`/api/marketplace/${marketplaceId}/products/${id}`);

  const cartItems = getState().cart.cartItems;
  const cartPrice = cartItems.reduce((acc, item) => acc + item.price * item.qty, 0);

  const { store } = getState().storeConfig;

  const discountedProduct = store?.discountedProducts?.filter((item) => data._id === item.id);

  if (discountedProduct?.length > 0) {
    discountedProduct.map((item) => {
      console.log(item.id);
      console.log(data._id);
      if (cartPrice >= item.threshold && data._id === item.id) {
        console.log("Discounted Price");
        dispatch({
          type: CART_ADD_ITEM,
          payload: {
            product: data._id,
            name: data.name,
            image: data.images[0],
            price: data.price,
            mrp: data.mrp,
            tax: data.tax,
            category: data.category,
            countInStock: data.countInStock,
            store: data.store,
            marketplace: data.marketplace,
            qty: 1,
          },
        });
      } else {
        console.log("Discount Condition Not Matched");
      }
      return true;
    });
  } else {
    dispatch({
      type: CART_ADD_ITEM,
      payload: {
        product: data._id,
        name: data.name,
        image: data.images[0],
        price: data.price,
        mrp: data.mrp,
        tax: data.tax,
        category: data.category,
        countInStock: data.countInStock,
        store: data.store,
        marketplace: data.marketplace,
        qty,
      },
    });
  }

  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));

  const updatedCartItems = getState().cart.cartItems;
  const updatedCartPrice = updatedCartItems.reduce((acc, item) => acc + item.price * item.qty, 0);

  store?.discountedProducts?.map((item) => {
    if (updatedCartPrice <= item.threshold) {
      dispatch({
        type: CART_REMOVE_ITEM,
        payload: item.id,
      });

      localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
    }
    return true;
  });
};

export const addToCartUsingSku = (sku, qty) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.token}`,
    },
  };
  const { data } = await axios.get(`/api/marketplace/${marketplaceId}/products/sku/${sku}`, config);

  dispatch({
    type: CART_ADD_ITEM,
    payload: {
      product: data._id,
      name: data.name,
      image: data.images[0],
      price: data.price,
      mrp: data.mrp,
      tax: data.tax,
      category: data.category,
      countInStock: data.countInStock,
      store: data.store,
      marketplace: data.marketplace,
      qty,
    },
  });

  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
};

export const addBundleToCart = (id, qty) => async (dispatch, getState) => {
  const { data } = await axios.get(`/api/bundle/${id}`);
  const { products } = data;

  products.map((product) => {
    axios.get(`/api/marketplace/${marketplaceId}/products/${product}`).then(function (response) {
      // handle success
      dispatch({
        type: CART_ADD_ITEM,
        payload: {
          product: response.data._id,
          name: response.data.name,
          image: response.data.images[0],
          price: response.data.price,
          mrp: response.data.mrp,
          tax: response.data.tax,
          category: response.data.category,
          countInStock: response.data.countInStock,
          store: response.data.store,
          marketplace: response.data.marketplace,
          qty: 1,
        },
      });
      console.log(response);
    });
    return true;
  });
  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
};

export const removeFromCart = (id) => (dispatch, getState) => {
  dispatch({
    type: CART_REMOVE_ITEM,
    payload: id,
  });
  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));

  const updatedCartItems = getState().cart.cartItems;
  const updatedCartPrice = updatedCartItems.reduce((acc, item) => acc + item.price * item.qty, 0);
  const { store } = getState().storeConfig;

  store?.discountedProducts?.map((item) => {
    if (updatedCartPrice <= item.threshold) {
      dispatch({
        type: CART_REMOVE_ITEM,
        payload: item.id,
      });

      localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
    }
    return true;
  });
};

export const saveShippingAddress = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_SHIPPING_ADDRESS,
    payload: data,
  });
  localStorage.setItem("shippingAddress", JSON.stringify(data));
};

export const saveMarketPlaceAddress = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_MARKETPLACE_ADDRESS,
    payload: data,
  });
  localStorage.setItem("marketPlaceAddress", JSON.stringify(data));
};

export const savePaymentMethod = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_PAYMENT_METHOD,
    payload: data,
  });
  localStorage.setItem("paymentMethod", JSON.stringify(data));
};
