import React from "react";
import { Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import SearchBox from "./SearchBox";
import { logout } from "../actions/userActions";
import SuperAdminHeader from "./utils/SuperAdminHeader";
import AdminHeader from "./utils/AdminHeader";
import HeaderAndFooterMobile from "./utils/HeaderAndFooterMobile";
import DeliveryAdminHeader from "./utils/DeliveryAdminHeader";
const Header = () => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const storeConfig = useSelector((state) => state.storeConfig);
  const { store } = storeConfig;

  const logoutHandler = () => {
    dispatch(logout());
  };

  return (
    <>
      {userInfo && userInfo.role.includes("superAdmin") ? (
        <SuperAdminHeader
          store={store}
          logoutHandler={logoutHandler}
          userInfo={userInfo}
        />
      ) : userInfo && userInfo.role.includes("deliveryAdmin") ? (
        <DeliveryAdminHeader
          store={store}
          logoutHandler={logoutHandler}
          userInfo={userInfo}
        />
      ) : (
        <>
          {userInfo &&
          (userInfo.role.includes("admin") ||
            userInfo.role.includes("deliveryAdmin")) ? (
            <AdminHeader
              store={store}
              logoutHandler={logoutHandler}
              userInfo={userInfo}
              cartItems={cartItems}
            />
          ) : window.innerWidth < 450 ? (
            <HeaderAndFooterMobile
              userInfo={userInfo}
              store={store}
              cartItems={cartItems}
            />
          ) : (
            <header
              className="headerShadow"
              style={{ position: "sticky", top: "0", zIndex: "2" }}
            >
              <Navbar bg="dark" variant="dark" expand="md" collapseOnSelect>
                <Container>
                  <LinkContainer to="/">
                    <Navbar.Brand>
                      <i className="fas fa-store"></i> {store.name}{" "}
                    </Navbar.Brand>
                  </LinkContainer>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Route
                      render={({ history }) => <SearchBox history={history} />}
                    />
                    <div className="nav">
                      <Nav className="ml-auto ">
                        <LinkContainer to="/cart" id="cart">
                          <Nav.Link>
                            <div className="cart-item">
                              <h6>{cartItems.length}</h6>
                            </div>
                            <i className="fas fa-shopping-cart"></i> Cart
                          </Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/bundle" id="bundle">
                          <Nav.Link>
                            <i className="fas fa-box"></i> Bundle
                          </Nav.Link>
                        </LinkContainer>
                        {userInfo ? (
                          <NavDropdown title={userInfo.name} id="username">
                            <LinkContainer to="/profile">
                              <NavDropdown.Item>profile</NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/myorders" id="myorders">
                              <NavDropdown.Item>My Orders</NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/login" id="LogOut">
                              <NavDropdown.Item onClick={logoutHandler}>
                                Logout
                              </NavDropdown.Item>
                            </LinkContainer>
                          </NavDropdown>
                        ) : (
                          <LinkContainer to="/login" id="Login">
                            <Nav.Link>
                              <i className="fas fa-user"></i>Sign In
                            </Nav.Link>
                          </LinkContainer>
                        )}
                        {userInfo &&
                          (userInfo.role.includes("admin") ||
                            userInfo.role.includes("deliveryAdmin")) && (
                            <NavDropdown title="Admin" id="adminmenu">
                              <LinkContainer
                                to="/admin/productlist"
                                id="products"
                              >
                                <NavDropdown.Item>Products</NavDropdown.Item>
                              </LinkContainer>
                              <LinkContainer to="/admin/orderlist" id="orders">
                                <NavDropdown.Item>Orders</NavDropdown.Item>
                              </LinkContainer>
                              <LinkContainer to="/admin/cartscreen" id="POS">
                                <NavDropdown.Item>POS</NavDropdown.Item>
                              </LinkContainer>
                            </NavDropdown>
                          )}
                      </Nav>
                    </div>
                  </Navbar.Collapse>
                </Container>
              </Navbar>
            </header>
          )}
        </>
      )}
    </>
  );
};

export default Header;
