import React from "react";
import { HashRouter as Router, Route } from "react-router-dom";
import { Container } from "react-bootstrap";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Homescreen from "./screens/Homescreen";
import ProductScreen from "./screens/ProductScreen";
import CartScreen from "./screens/CartScreen";
import LoginScreen from "./screens/LoginScreen";
import LoginEmail from "./screens/LoginEmail";
import RegisterScreen from "./screens/RegisterScreen";
import ProfileScreen from "./screens/ProfileScreen";
import ShippingScreen from "./screens/ShippingScreen";
import PaymentScreen from "./screens/paymentScreen";
import PlaceOrderScreen from "./screens/PlaceOrderScreen";
import OrderScreen from "./screens/OrderScreen";
import MyOrderScreen from "./screens/MyOrderScreen";
import UserListScreen from "./screens/UsesrListScreen";
import UserEditScreen from "./screens/UserEditScreen";
import ProductListScreen from "./screens/ProductListScreen";
import ProductEditScreen from "./screens/ProductEditScreen";
import OrderListScreen from "./screens/OrderListScreen";
import BundleScreen from "./screens/BundleScreen";
import LoginPhone from "./screens/LoginEmail";
import CartScreenAdmin from "./screens/CartScreenAdmin";
import BillingOrders from "./screens/BillingOrders";
import DashBoard from "./screens/DashBoard";
import { PrivacyPolicy } from "./components/PrivacyPolicy";
import AboutUs from "./components/AboutUs";
import RefundPolicy from "./components/RefundPolicy";
import TermsAndConditions from "./components/TermsAndConditions";
import ShippingPolicy from "./components/ShippingPolicy";
import AllProductsData from "./screens/AllProductsData";
import InvoiceScreen from "./screens/InvoiceScreen";
import InvoiceListScreen from "./screens/InvoiceListScreen";
import InvoiceCreateScreen from "./screens/InvoiceCreateScreen";
import OnBoardStoreScreen from "./screens/OnBoardStoreScreen";
import OnBoardStoreListScreen from "./screens/OnBoardStoreListScreen";
import OnBoardStoreEditScreen from "./screens/OnBoardStoreEditScreen";
import MarketPlaces from "./screens/marketPlace/MarketPlaces";
import MarketPlacesEdit from "./screens/marketPlace/MarketPlacesEdit";
import MarketPlacesView from "./screens/marketPlace/MarketPlacesView";
import MarketSubCategories from "./screens/marketPlace/MarketSubCategories";
import LocationScreen from "./screens/locationScreen";
import LocationNotInService from "./screens/LocationNotInService";

const App = () => {
  return (
    <Router>
     
        <Header />
        <main className="py-2 mb-5">
          <Container>
            <Route path="/location" component={LocationScreen} exact />
            <Route
              path="/notinservice"
              component={LocationNotInService}
              exact
            />

            <Route path="/" component={Homescreen} exact />
            <Route path="/onboard" component={OnBoardStoreScreen} exact />
            <Route
              path="/superadmin/storelist"
              component={OnBoardStoreListScreen}
              exact
            />
            <Route
              path="/superadmin/marketplaces"
              component={MarketPlaces}
              exact
            />
            <Route
              path="/superadmin/marketplace/:id/edit"
              component={MarketPlacesEdit}
              exact
            />
            <Route
              path="/superadmin/marketplace/:id/view"
              component={MarketPlacesView}
              exact
            />
            <Route
              path="/superadmin/marketplace/:id/category/:name"
              component={MarketSubCategories}
              exact
            />
            <Route
              path="/superadmin/store/:id/edit"
              component={OnBoardStoreEditScreen}
              exact
            />

            <Route path="/page/:pageNumber" component={Homescreen} exact />
            <Route
              path="/search/:keyword/page/:pageNumber"
              component={Homescreen}
              exact
            />
            <Route path="/product/:id" component={ProductScreen} />
            <Route path="/cart/:id?" component={CartScreen} />
            <Route path="/login" component={LoginScreen} />
            <Route path="/login/email" component={LoginEmail} />
            <Route path="/phoneSignUp" component={LoginPhone} />
            <Route path="/register" component={RegisterScreen} />
            <Route path="/profile" component={ProfileScreen} />
            <Route path="/shipping" component={ShippingScreen} />
            <Route path="/payment" component={PaymentScreen} />
            <Route path="/placeorder" component={PlaceOrderScreen} />
            <Route path="/order/:id" component={OrderScreen} />
            <Route path="/myorders" component={MyOrderScreen} />
            <Route path="/admin/userlist" component={UserListScreen} exact />
            <Route
              path="/admin/userlist/:pageNumber"
              component={UserListScreen}
              exact
            />
            <Route path="/admin/user/:id/edit" component={UserEditScreen} />
            <Route
              path="/admin/productlist"
              component={ProductListScreen}
              exact
            />
            <Route
              path="/admin/productlist/search/:keyword"
              component={ProductListScreen}
              exact
            />
            <Route
              path="/admin/allProducts"
              component={AllProductsData}
              exact
            />
            <Route path="/admin/dashboard" component={DashBoard} exact />
            <Route
              path="/admin/productlist/:pageNumber"
              component={ProductListScreen}
              exact
            />
            <Route
              path="/admin/product/:id/edit"
              component={ProductEditScreen}
            />
            <Route path="/admin/orderlist" component={OrderListScreen} exact />
            <Route path="/category/:category" component={Homescreen} exact />
            <Route
              path="/admin/orderlist/:orderStatus/:pageNumber"
              component={OrderListScreen}
              exact
            />
            <Route path="/admin/cart" component={CartScreenAdmin} />
            <Route path="/admin/invoice/:id/edit" component={InvoiceScreen} />
            <Route path="/admin/invoices" component={InvoiceListScreen} />
            <Route
              path="/admin/invoice/create"
              component={InvoiceCreateScreen}
            />

            <Route path="/search/:keyword" component={Homescreen} exact />
            <Route
              path="/category/:category/page/:pageNumber"
              component={Homescreen}
              exact
            />
            <Route
              path="/subcategory/:subcategory"
              component={Homescreen}
              exact
            />
            <Route
              path="/subcategory/:subcategory/page/:pageNumber"
              component={Homescreen}
              exact
            />
            <Route path="/brand/:brand" component={Homescreen} exact />
            <Route
              path="/brand/:brand/page/:pageNumber"
              component={Homescreen}
              exact
            />
            <Route path="/store/:storeId" component={Homescreen} exact />
            <Route
              path="/store/:storeId/page/:pageNumber"
              component={Homescreen}
              exact
            />
            <Route path="/bundle/:id?" component={BundleScreen} exact />
            <Route path="/print/:id" component={BillingOrders} exact />
            <Route path="/privacypolicy" component={PrivacyPolicy} exact />
            <Route path="/aboutus" component={AboutUs} exact />
            <Route path="/refundpolicy" component={RefundPolicy} exact />
            <Route
              path="/termsandconditions"
              component={TermsAndConditions}
              exact
            />
            <Route path="/shippingpolicy" component={ShippingPolicy} exact />
          </Container>
        </main>
        <Footer />
     
    </Router>
  );
};

export default App;
