import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { OrdersDoughnut } from "../charts/OrdersDoughnut";
import { TopSellsBar } from "../charts/TopSellsBar";
import { LessTenProducts } from "../charts/LessTenProducts";
import { SaleReportChart } from "../charts/SaleReportChart";
import { OrdersSummary } from "../charts/OrdersSummary";
import { MonthlySummary } from "../charts/MonthlySummary";
import { RevenueReport } from "../charts/RevenueReport";
// import { CardData } from '../charts/CardData'

const DashBoard = () => {
  /*  const groupBy = 'day' */
  const [online, setOnline] = useState("online");

  /* useEffect(() => {}, [online]); */
  const changeHandler = (e) => {
    e.preventDefault();
    setOnline(e.target.value);
  };

  return (
    <>
      {/* <CardData /> */}
      <button
        value={"online"}
        onClick={changeHandler}
        defaultChecked
        className={"button-30"}
      >
        Online
      </button>
      <button
        value={"pos"}
        onClick={changeHandler}
        className={"button-30"}
        style={{ marginLeft: "1rem" }}
      >
        POS
      </button>

      <Row style={{ marginBottom: "2rem" }}>
        <Col xs={12} md={4}>
          <OrdersSummary orderType={online} />
        </Col>
        <Col xs={12} md={4}>
          <MonthlySummary orderType={online} />
        </Col>
        <Col xs={12} md={6}>
          <SaleReportChart orderType={online} />
        </Col>
        <Col xs={12} md={6}>
          <RevenueReport orderType={online} />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={3}>
          <OrdersDoughnut />
        </Col>
        <Col xs={12} md={6}>
          <TopSellsBar />
        </Col>

        <Col xs={12} md={3}>
          <LessTenProducts />
        </Col>
      </Row>
    </>
  );
};

export default DashBoard;
